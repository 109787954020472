import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { AlertInput, ModalController } from '@ionic/angular';
import { ConfirmDelete } from '../../../decorators/confirmdelete.decorator';
import { Vehicle } from '../../../interfaces/vehicle.interface';
import { PortalSettings, vhcrecord } from '../../../interfaces/vhcrecord.interface';
import { DmsService } from '../../../services/dms.service';
import { HelperService } from '../../../services/helper.service';
import { JSettings, SupaService } from '../../../services/supa.service';
import { VhcBrakeModule } from '../../../vhcmodules/brakemodule/vhcbrakemodule';
import { VhcCross_sellModule } from '../../../vhcmodules/cross_sellmodule/cross_sellmodule';
import { VhcQuestionblockModule } from '../../../vhcmodules/questionblock/vhcquestionblockmodule';
import { VhcTyreModule } from '../../../vhcmodules/tyremodule/vhctyremodule';
import { Vhcmodule } from '../../../vhcmodules/vhcmodule';
import { AuthobjviewDialog } from '../authobjview/authobjview.dialog';
import { AuthviewDialog } from '../authview/authview.dialog';

@Component({
  selector: 'app-authscreen',
  templateUrl: './authscreen.dialog.html',
  styleUrls: ['./authscreen.dialog.scss'],
})
export class AuthscreenDialog implements OnInit, OnDestroy {

  @Input('vhcrecord') vhcrecord: vhcrecord
  @Input('vehicle') vehicle: Vehicle
  @Input('completed') completed:string
  @Input('title') title:string
  @Input('premodules') premodules: Vhcmodule[]
  @Input('mpimodules') mpimodules: Vhcmodule[]
  @Input('qimodules') qimodules: Vhcmodule[]

  tabIndex = 0

  portalSettings: PortalSettings = {
    expectedHours: 2,
    expectedMinutes: 30,
    pickupHour: 16,
    pickupMinutes: 0,
    includeSunday: true,
    includeMonday: true,
    includeTuesday: true,
    includeWednesday: true,
    includeThursday: true,
    includeFriday: true,
    includeSaturday: true
  }

  ngOnDestroy() {
    this.storeVars();
  }

  storeVars() {
    const jstring = JSON.stringify(this.portalSettings);
    const oldstring = JSON.stringify(this.vhcrecord.portalsettings)
    if (jstring != oldstring) {
      this.vhcrecord.portalsettings = this.portalSettings;
      localStorage.setItem('PORTALSETTINGS', JSON.stringify(this.portalSettings))
    }
    localStorage.setItem('AUTHSCREENSELECTEDTAB', this.tabIndex.toString())
  }

  recallVars() {
    if (this.vhcrecord.portalsettings == null) {
      const s = localStorage.getItem('PORTALSETTINGS')
      if (s) {
        this.portalSettings = JSON.parse(s);
      }
    } else {
      this.portalSettings = this.vhcrecord.portalsettings
    }
    if (localStorage.getItem('AUTHSCREENSELECTEDTAB')) {
      this.tabIndex = Number.parseInt(localStorage.getItem('AUTHSCREENSELECTEDTAB'))
    }
  }

  constructor(private modalController: ModalController, private supa: SupaService, private helper: HelperService, private dms: DmsService) { }

  ngOnInit() {
    this.recallVars()
  }

  dismissModal() {
    this.modalController.dismiss();
  }
  
  saveModal() {
    this.modalController.dismiss({savedata: true});
  }

  @ConfirmDelete()
  deleteauth(authobj, idx) {
    const modulePredicate = ((module:Vhcmodule, datetimestr:string, basearray:any, moduleindex:number) => {
      if (module.type == 'Questionblock') {
        (module as VhcQuestionblockModule).questions.forEach((v,idx) => {if(datetimestr == v.dateAuthorised) { basearray[moduleindex].questions[idx].dateAuthorised = "" }});
      } else if (module.type == 'Tyres') {
        (module as VhcTyreModule).data.tyres.forEach((v,idx) => {if(datetimestr == v.dateAuthorised) { basearray[moduleindex].data.tyres[idx].dateAuthorised = "" }});
      } else if (module.type == 'Brakes') {
        (module as VhcBrakeModule).data.brakes.forEach((v,idx) => {if(datetimestr == v.dateAuthorised) { basearray[moduleindex].data.brakes[idx].dateAuthorised = "" }});
      } else if (module.type == 'Cross_sell') {
        (module as VhcCross_sellModule).data.forEach((v,idx) => {if(datetimestr == v.dateAuthorised) { basearray[moduleindex].data[idx].dateAuthorised = "" }});
      } else {
        delete module.dateAuthorised;
      }
    });
    console.log(authobj);
    this.premodules.forEach((m,i)=>modulePredicate(m, authobj.datetime, this.premodules, i));
    this.qimodules.forEach((m,i)=>modulePredicate(m, authobj.datetime, this.qimodules, i));
    this.mpimodules.forEach((m,i)=>modulePredicate(m, authobj.datetime, this.mpimodules, i));
    this.vhcrecord.authobjects.splice(idx,1);
  }

  async addAuthorisation() {
    let settings: JSettings = await this.supa.Settings();
    let inputs: AlertInput[] = [{
      label: 'Include pre inspection',
      value: 'includepre',
      type: 'checkbox',
      checked: true
    }];
    if (settings.lang2enabled) {
      inputs.unshift({
        label: `Display in ${settings.lang2name} instead of ${settings.lang1name}`,
        value: 'displaysecondlang',
        type: 'checkbox',
        checked: false
      })
    }

    this.helper.createAndOpenAlert('Authorisation settings', 'Please choose how you would like to display the authorisation modal', async (v)=>{
      if (v != undefined) {
        const modal = await this.modalController.create({
          component: AuthviewDialog,
          componentProps: {
            vhcrecord: this.vhcrecord,
            vehicle: this.vehicle,
            premodules: this.premodules,
            mpimodules: this.mpimodules,
            qimodules: this.qimodules,
            settings: settings,
            isPrimaryLang: !v.includes('displaysecondlang'),
            includepre: v.includes('includepre'),
            includempi: this.vhcrecord.completedmpi,
            includeqi: this.vhcrecord.completedqi
          },
          cssClass: 'fullscreen-modal doubled-modal'
        });
      
        await modal.present();
      }
    }, inputs, 'accept', 'cancel', 'wider-alert');
  }

  async sendLink() {
    let settings = await this.supa.Settings();
    this.helper.createAndOpenAlert('Language', 'Do you want to send English or Arabic?', async (islang1)=>{
      const descript = `<p class="ion-padding">
        Please communicate with the: ${(this.vehicle.communicatewithowner? 'owner':'driver')}<br /><br />
        <strong>Owner phone:</strong> ${this.vehicle.ownerphone}<br />
        <strong>Driver phone:</strong> ${this.vehicle.driverphone}<br /><br/>
        <i>Please make sure the phone number is <br />in this format: 0590012345</i>
      </p>`
      const themsg = (islang1 ? 'Please go tho this url to review the pending items' : 'Please go tho this url to review the pending items') + ' https://vhcportal.staging.iq-motive.com/?k='+this.vhcrecord.portalpass
      let {data} = await this.helper.presentNewEditDialog('SMS Message', [
        { name: 'phone', label: 'Phone number', type: 'text', value: (this.vehicle.communicatewithowner ? this.vehicle.ownerphone : this.vehicle.driverphone) },
        { name: 'message', label: 'Message', type: 'textarea', value: themsg },
      ], undefined, 'doubled-modal', descript)
      let reslt = await this.dms.sendIqSms(data.data.phone, data.data.message)
      this.helper.notify((reslt as any).status, 'dark');
    }, [
        {
            name: 'lang1',
            type: 'radio',
            label: settings.lang1name,
            value: true,
            checked: false
        },{
            name: 'lang2',
            type: 'radio',
            label: settings.lang2name,
            value: false,
            checked: true
        },
    ])
  }

  openAuthorisation() {
    this.helper.createAndOpenModal(AuthobjviewDialog, {
      authobjs: this.vhcrecord.authobjects,
      premodules: this.premodules,
      mpimodules: this.mpimodules,
      qimodules: this.qimodules,
    }, 'doubled-modal big-modal')
  }

  complete() {
    this.helper.promptYesNo('Do you want to complete the authorisation step?', 'The authorisation step will be finished and the next step will unlock.', ()=>{
      this.modalController.dismiss({completestep: true});
    }, 'Complete step', 'Cancel')
  }

}
